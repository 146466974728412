<template>
  <PreviewLesson
    v-if="slideshow.id"
    :id="slideshow.id"
    :title="menuTitle"
    class="h-screen"/>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect, nextTick, onMounted } from 'vue';
import { PreviewLesson, createPresentation } from '@nwire/amit-design-system';
import { fetchCourse, fetchPages } from '/@/app/services/courses';
import { useRoute, useRouter } from 'vue-router';
import { useState } from '/@/app/composables/useState';
// import { useActivities } from '/@/app/composables/useActivities';
import { useAccountStore } from '/@/app/store/account';
import { useLocalStorage } from '@vueuse/core';
import { useStudentsStore } from '/@/app/store/students';

interface OutlineItem {
  id: string;
  title: number;
  selected: boolean;
}

const accountStore = useAccountStore();
const studentsStore = useStudentsStore();
// const { recordSubmission } = useActivities();
const { getDisabled, getPageState, getRules } = useState();
const user = computed(() => accountStore.user);
const menuTitle = computed(() =>
  slideshow.value.lesson
    ? `שיעור ${currentOutlineIndex.value + 1} - ${slideshow.value.title}`
    : '',
);

const route = useRoute();
const router = useRouter();
const slideshow = ref({
  id: '',
  course: '',
  lesson: 2,
  cover: '',
  introduction: '',
  title: '',
  subject: '',
  description: '',

  welcome_message: '',
  final_message: '',

  unit: {},

  display: {
    rtl: true,
  },
  sections: [],
});

const outline = ref<OutlineItem[]>([]);
const locale = ref();
const isPreview = ref(true);
const currentTracks = useLocalStorage<string[]>(
  'preview-tracks-' + route.params.course,
  [],
);
const rtl = computed(() => !!slideshow.value?.display?.rtl);
const page = computed(() => route.query.page);
const {
  onOutlineChange,
  onTrackChange,
  onAction,
  onReady,
  onSlide,
  onSubmit,
  onNext,
} = createPresentation({
  slideshow,
  outline,
  locale,
  tracks: currentTracks,
  user,
  page,
  isPreview,
});

onOutlineChange(lesson =>
  router.push({
    name: 'preview',
    params: {
      lesson: lesson.id,
      course: route.params.course,
    },
    query: {
      course_module_group: route.query['course_module_group'],
    },
  }),
);

watch(
  () => rtl.value,
  () => {
    nextTick(() => {
      const htmlElement = document.getElementsByTagName('html')[0];
      if (!rtl.value) htmlElement.setAttribute('dir', 'ltr');
      htmlElement.setAttribute('lang', 'en');
    });
  },
  { immediate: true },
);

onTrackChange(event => {
  console.log(event);
});

// every use interaction with page (blocks)
// next is disabled by default if page settings does not say other
// here we can enable next
onAction(({ context, event }) => {
  if (!context) return;

  const contextState = context.state();
  const contextBlocks = context.flatBlocks();
  if (event.value) {
    context.updateState(event.block.id, event.value);
  }
  context.setDisabled(getDisabled(contextBlocks, contextState));
});

const handleSlide = async context => {
  const contextBlocks = context.blocks();
  const state = getPageState(contextBlocks);
  context.setState(state);
  const personaSelectionBlock = contextBlocks.find(
    block => block.name === 'persona_selection',
  );
  if (personaSelectionBlock) {
    const track = state[personaSelectionBlock.id];
    if (!currentTracks.value.includes(track)) {
      currentTracks.value.push(track);
    }
  }
  addPropsToBlocks(contextBlocks);

  context.setDisabled(getDisabled(contextBlocks, state));
  const rules = await getRules(context.getId());
  context.setRules(rules);
};

onReady(async ({ context }) => {
  await handleSlide(context);
});

onSlide(async ({ context }) => {
  await handleSlide(context);
});

onSubmit(async ({ block }) => {
  if (block.name == 'poll') {
    block.content.answers = [
      {
        title: ' תשובה לדוגמה',
        id: block.content.options[0].id,
        count: 1,
        currentUserVote: true,
      },
    ];
    block.state.submitting = false;
    block.state.submitted = true;
  }
  //TODO - need to fix server for getting feedback
  // const contextState = context.state();
  // const { feedback } = await recordSubmission(contextState, block);
  // if (feedback) {
  // context.setCurrentBlockFragment(' ');
  // }
});
onNext(async context => {
  if (context.get()) {
    context.next({});
    return;
  }

  // const contextState = context.state();
  // const contextBlocks = context.blocks();
  // const questionBlock = contextBlocks.find(block => block.type === 'question');
  // const hasCalendarQuestion = contextBlocks.findIndex(block => block.type === 'question' && block.name === 'calendar') > -1;
  let instructions: string | null = null;

  context.setLoading(true);
  //TODO - need to fix server for getting feedback
  // if (questionBlock && !hasCalendarQuestion) {
  //   const submission = await recordSubmission(contextState, questionBlock);
  //   instructions = submission.instructions;
  //   if (submission.feedback) {
  //     context.push(submission.feedback);
  //   }
  //   if (submission.exerciseFeedback) {
  //     context.push(submission.exerciseFeedback);
  //   }
  // }

  let nextSlide = context.sync({ instructions });
  context.setLoading(false);
  if (!nextSlide) {
    return;
  }

  let disabled = false;
  let state = nextSlide.state;

  context.next({
    state,
    disabled,
  });
});

const fetchContent = (courseId, lessonId) => {
  const { onSuccess: onCourseFetched } = fetchCourse(courseId);

  onCourseFetched(({ data: course }) => {
    outline.value = course.outline.map(({ id }, index) => ({
      id,
      title: index + 1,
      selected: id === lessonId,
    }));
    locale.value = course?.locale;
  });

  const { onSuccess: onLessonFetched } = fetchPages(courseId, lessonId);
  onLessonFetched(({ data: lesson }) => {
    slideshow.value = lesson;
  });
};

const addPropsToBlocks = blocks => {
  blocks.forEach(block => {
    if (block.name === 'collaborative_board') {
      const group = route.query['course_module_group'];
      if (group) {
        try {
          const channel_url = studentsStore
            .getCourseModule(group, route.params.course)
            ?.settings.collaborative_board.find(
              channel => channel.block === block.id,
            )?.channel_url;
          block.content['channelUrl'] = channel_url;
          block.content['currentUserAccount'] = {
            id: user.value.id,
            name: user.value.fullName,
          };
          block.content['demo'] = false;
        } catch (error) {
          console.error(error, 'error in addPropsToBlocks');
          block.content['demo'] = true;
        }
      } else {
        block.content['demo'] = true;
      }
    }
  });
};

watch(
  () => route.params.lesson,
  () => window.location.reload(),
);
watchEffect(() => fetchContent(route.params.course, route.params.lesson));

onMounted(() => {
  const group = route.query['course_module_group'];
  if (group) {
    studentsStore.setCourseModule(group, route.params.course);
  }
});

const currentOutlineIndex = computed<number>(() => {
  let selected = outline.value.findIndex(item => item.selected);
  return selected >= 0 ? selected : 0;
});
</script>
